<template>
  <div class="c-app flex-row align-items-center" :class="{ 'c-dark-theme': $store.state.darkMode }">
    <CContainer>
        <CRow class="justify-content-center">
            <CCol md="7">
                <CCardGroup>
                    <CCard class="p-7">
                        <CCardHeader v-if="adminType!=0">
                            초기 비밀번호를 변경합니다.
                        </CCardHeader>
                        <CCardHeader v-if="adminType==0">
                            관리자 아이디 및 초기 비밀번호를 변경합니다.
                        </CCardHeader>
                        <CCardBody>
                            <CRow v-if="adminType==0">
                                <CCol sm="12">
                                    <CInput
                                        label="변경할 아이디"
                                        :value.sync="$v.form.changeAdminId.$model"
                                        :isValid="!$v.form.changeAdminId.$error"
                                        horizontal
                                    />
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="12">
                                    <CInput
                                        label="새 비밀번호 입력"
                                        type="password"
                                        :value.sync="$v.form.password.$model"
                                        :isValid="!$v.form.password.$error"
                                        autocomplete="new-password"
                                        invalidFeedback="비밀번호는 영문 대/소문자, 숫자, 특수문자를 3가지 이상 조합하여 9~20자리 이상 설정해야합니다."
                                        horizontal
                                    />
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="12">
                                    <CInput
                                        label="새 비밀번호 확인"
                                        type="password"
                                        :value.sync="$v.form.confirmPassword.$model"
                                        :isValid="!$v.form.confirmPassword.$error"
                                        autocomplete="new-password"
                                        invalidFeedback="비밀번호가 일치하지 않습니다."
                                        horizontal
                                        @keyup.enter="modifyPswd()"
                                    />
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol>                      
                                    <CButton block color="success" class="px-1" @click="modifyPswd()">저장</CButton>  
                                </CCol>
                                <CCol>                      
                                    <CButton block color="danger" class="px-1" @click="cancel()">취소</CButton>  
                                </CCol>
                            </CRow>
                        </CCardBody>
                    </CCard>
                </CCardGroup>
            </CCol>
        </CRow>
    </CContainer>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate"
import { required, sameAs, helpers } from "vuelidate/lib/validators"
import RSAKey from '@/js/rsa/rsa.js'
export default {
    name: 'InitPasswordChange',
    mixins: [validationMixin],
    data () {
        return {
            isModalOpen:true,
            afterClick:false,
            form:{
                changeAdminId:'',
                password:'',
                confirmPassword:'',
            },
            pubRsaKey:[]
        }
    },
    validations: {
        form: {
            changeAdminId:{
                required
            },
            password: {
                required,
                strongPass: helpers.regex('strongPass', /^.*(?=^.{9,20}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!,@,#,$,%,^,&,*]).*$/)
            },
            confirmPassword: {
                required,
                sameAsPassword: sameAs("password")
            },
        }
    },
    computed:{
        adminType(){
            return this.$store.state.loginStore.adminType
        }
    },
    mounted() {
        this.$v.form.$touch()
        this.getRsaKey()
    },
    methods: {
        async modifyPswd(){  
            this.afterClick = true   
            if(this.validate()){  
                const rsa = new RSAKey() 
                rsa.setPublic(this.pubRsaKey.publicKeyModulus, this.pubRsaKey.publicKeyExponent)

                let payload ={
                    adminKey : this.$store.state.loginStore.adminKey,
                    adminId: this.$store.state.loginStore.id,
                    changeAdminId: this.form.changeAdminId,
                    pswd : rsa.encrypt(this.form.password),
                    currentPswdYn: false,
                    initAdminSetting: true,
                    adminType: this.adminType
                }
                this.loading=true 
                try {
                    await this.$store.dispatch('adminInfo/modifyMyAccountPswd' , payload)
                    await alert("저장하였습니다.")   
                    
                    if(this.adminType == '0' && (this.form.changeAdminId != null || this.form.changeAdminId != '')){
                        window.setTimeout(()=>{
                        this.$router.push({name: 'Login'})  
                        }, 500)
                    }else{
                        window.setTimeout(()=>{
                        this.$router.push({name: 'Dashboard'})  
                        }, 500)
                    }
                } catch(error){
                    this.$response(error)
                    this.loading=false
                    this.getRsaKey() //실패시 재호출
                }
            }
            this.afterClick = false

            return true
        },
        validate(){
            if(!this.$v.form.changeAdminId.required && this.adminType == 0){
                alert("변경할 아이디를 입력해주세요.");
                return false
            }
            else if (!this.$v.form.password.required) {
                alert("새 비밀번호를 입력해주세요.")
                return false
            }
            else if (!this.$v.form.password.strongPass) {
                alert("새 비밀번호를 규칙에 맞게 입력해주세요.")
                return false
            }
            else if (!this.$v.form.confirmPassword.sameAsPassword) {
                alert("새 비밀번호 확인을 정확하게 입력해주세요.")
                return false
            }
            return true
        },
        cancel(){
            window.setTimeout(()=>{
                this.$router.push({name: 'Login'})  
            }, 500)
        },
        async getRsaKey(){
            try{
                await this.$store.dispatch("loginStore/getRsaPubKey")
                this.pubRsaKey = this.$store.state.loginStore.pubKey
            }catch(error){
                console.log(error)
            }
        }
    }
}
</script>
